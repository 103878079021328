export const query = `query getRelatedItems($externalId: String!, $productIds: [String!]!) {
    appSettings(externalId: $externalId) {
      widgetSettings
    }
    catalog {
      relatedProducts(productIds: $productIds) {
        id
        options(limit: 1) {
          id
        }
        customTextFields(limit: 1) {
          title
        }
        productType
        ribbon
        price
        comparePrice
        isInStock
        urlPart
        formattedComparePrice
        formattedPrice
        digitalProductFileItems {
          fileType
        }
        name
        media {
          url
          index
          width
          mediaType
          altText
          title
          height
        }
        isManageProductItems
        isTrackingInventory
        inventory {
          status
          quantity
        }
      }
    }
  }
  `;
