export const query = `query getFilteredProducts($mainCollectionId: String!, $filters: ProductFilters, $sort: ProductSort, $offset: Int, $limit: Int) {
  catalog {
    category(categoryId: $mainCollectionId) {
      numOfProducts
      productsWithMetaData(filters: $filters, limit: $limit, sort: $sort, offset: $offset, onlyVisible: true) {
        totalCount
        list {
          id
          productType
          ribbon
          price
          comparePrice
          isInStock
          urlPart
          formattedComparePrice
          formattedPrice
          digitalProductFileItems {
            fileId
            fileType
            fileName
          }
          name
          media {
            url
            width
            height
            title
            altText
            mediaType
            index
            fullUrl
            width
            height
            videoFiles {
              format
              quality
              url
              width
              height
              format
            }
            videoType
          }
          options(limit: 1) {
            id
          }
          customTextFields(limit: 1) {
            title
          }
          creationDate
          isManageProductItems
          isTrackingInventory
          inventory {
            status
            quantity
          }
        }
      }
    }
  }
}
`;
